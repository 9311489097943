import React from 'react'

import { Helmet } from 'react-helmet'
import { useAudioPlayer } from '../components/Context';

import './help.css'

const Help = (props) => {
  const { audioTitle } = useAudioPlayer()
  return (
    <div className="help-container">
      <Helmet>
        <title>{audioTitle ? audioTitle : "DemandTheHam - The Ham Radio Show"}</title>
        <meta property="og:title" content="Help - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1"
        />
      </Helmet>
    
    <div className="help-container">
      <iframe src="https://app.thehamradio.show/demandtheham-help/" style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
    </div>


    </div>
  )
}

export default Help
