import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import SpankBankGallery from '../components/SpankBankGalleryCard'
import { useAudioPlayer } from '../components/Context';
import './spank-bank-selection.css'


const SpankBankSelection = (props) => {
  const [galleryProps, setGalleryProps] = useState([])
  const { audioSrc } = useAudioPlayer()

  useEffect(() => {
    axios
      .get('https://hamradioshowpodcast.com/wp-json/wp/v2/posts?categories=23')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const posts = response.data.map((post) => ({
            id: post.id, // Correctly assign the post id
            picTitle: post.title.rendered,
            imageSrc: post.better_featured_image?.media_details?.sizes?.medium?.source_url || ''
          }))
          setGalleryProps(posts)
        }
      })
      .catch((error) => {
        console.error('Error fetching data: ', error)
      })
  }, [])

  return (
    <div className="spank-bank-selection-container">
      <Helmet>
        <title>{audioSrc ? audioSrc : "DemandTheHam - Spank Bank (18+)"}</title>
        <meta property="og:title" content="SpankBank-Selection - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div style={{ margin: '16px', display: 'flex', flexWrap: 'wrap' , alignItems:'flex-start'}}>
        {galleryProps.map((post, index) => (
          <SpankBankGallery
            key={index}
            rootClassName="spank-bank-gallery-root-class-name"
            picTitle={post.picTitle}
            imageSrc={post.imageSrc}
            onClickValue={post.id} // Ensure id is passed correctly
          />
        ))}
      </div>
      
    </div>
  )
}

export default SpankBankSelection