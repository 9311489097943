import React, {useContext} from 'react'
import { useHistory } from 'react-router-dom';
import { useAudioPlayer } from './Context'

import PropTypes from 'prop-types'

const DTHEpisodeCatalog = (props) => {
  const { title, descript, url, key } = props;
  const history = useHistory()
  const { handleOnClick, setAudioTitle, setIsPlaying, isPlaying, setAudioSrc,isCasting, SetisCasting} = useAudioPlayer();
  const cjs = new Castjs();

  const episodeDetailHandler = () => {
    const { title } = props;
    history.push(`/episode?title=${title}`);

  }

  const handleCastClick = () => {
    SetisCasting(true);
    setAudioSrc(props.url);
    setAudioTitle(props.title);
    if (isPlaying) {
      setIsPlaying(false); // Make sure to stop existing playback
      setAudioSrc(''); // Unload the current audio source
    }
    setTimeout(() => {
      cjs.cast(props.url, {
        title: props.title,
        poster:  'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg' , // Set poster if available
      });
      cjs.play();
    }, 100); // Adjust the delay time as necessary
  };
  
  const handleClick = () => {
    if (isPlaying) {
      setIsPlaying(false); // Stop the audio
      setAudioSrc(''); // Unload the current audio source

      // Set the new audio source for the clicked item after a slight delay to ensure state updates
      setTimeout(() => {
        setAudioSrc(props.url);
        setAudioTitle(props.title);
        setIsPlaying(true);
      // Play the new audio automatically
      }, 100); // Adjust the delay time as necessary
    } else {
      // Set the new audio source for the clicked item directly
      setAudioSrc(props.url);
      setAudioTitle(props.title);
      setIsPlaying(true); // Play the new audio automatically
    }
  };
  
  return (
    <>
      <div className={`dth-episode-catalog-container ${props.rootClassName} `}>
        <div className="dth-episode-catalog-container1">
          <div className="dth-episode-catalog-container2">
            <div className="dth-episode-catalog-container3">
              <h2 className="dth-episode-catalog-text" onClick={episodeDetailHandler}>{props.title}</h2>
              <div className='Playlist-Duration-Container'><h4 className='Playlist-Duration-text'>{props.duration}</h4></div>
            </div>
            <div className="dth-episode-catalog-container4"></div>
            <div className="dth-episode-catalog-container5">
              <svg
                id="DTHPlay"
                viewBox="0 0 1024 1024"
                className="dth-episode-catalog-icon"
                onClick={handleClick}
              >
                <path d="M342 214l468 298-468 298v-596z"></path>
              </svg>
              <svg
                id="DTH  Cast episode"
                viewBox="0 0 1024 1024"
                className="dth-episode-catalog-icon2"
                onClick={handleCastClick}
              >
                <path d="M726 44q34 0 59 25t25 59v726q0 34-25 59t-59 25h-90q-6-86-40-170h130v-554h-428v256q-50-22-84-28v-314q0-34 25-60t59-26zM86 512q194 0 331 137t137 333h-84q0-158-113-271t-271-113v-86zM86 854q52 0 90 38t38 90h-128v-128zM86 682q124 0 211 88t87 212h-86q0-88-62-151t-150-63v-86z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`

        .Playlist-Duration-Container{
          margin-left: 6px;
          margin-right: 6px;
           
        }
        .Playlist-Duration-text{
          color: #000000;
          font-size: 12px;
          font-weight: 700;
          backgrond-color: #ffffff;
          padding: 2px;
        }

        @media (max-width: 550px) {
          .dth-episode-catalog-container3 {
            flex-direction: column;
          }
        }
          .dth-episode-catalog-container {
            width: 100%;
            height: 100%;
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: row;
            justify-content: center;
          }
          .dth-episode-catalog-container1 {
            flex: 0 0 auto;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .dth-episode-catalog-container2 {
            flex: 0 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .dth-episode-catalog-container3 {
            flex: 0 0 auto;
            width: 75%;
            height: 100px;
            display: flex;
            align-items: center;
            margin-left: var(--dl-space-space-unit);
            flex-direction: row;
            justify-content: center;
            background-color: #000000;
          }
          .dth-episode-catalog-text {
            color: #ffffff;
            align-self: center;
            margin-top: 0px;
            text-align: center;
            margin-left: 0px;
            padding-left: var(--dl-space-space-halfunit);
            padding-right: var(--dl-space-space-halfunit);
            background-color: #040404;
            overflow-wrap: break-word;
            hyphens: auto;
            
          }
          .dth-episode-catalog-text1 {
            color: #ffffff;
            margin: var(--dl-space-space-unit);
          }
          .dth-episode-catalog-container4 {
            flex: 0 0 auto;
            width: 25%;
            border: 2px dashed rgba(120, 120, 120, 0.4);
            height: 100px;
            display: none;
            align-items: center;
            flex-direction: column;
            justify-content: center;
          }
          .dth-episode-catalog-container5 {
            flex: 0 0 auto;
            width: 25%;
            height: 100px;
            display: flex;
            align-items: center;
            margin-right: var(--dl-space-space-halfunit);
            flex-direction: row;
            justify-content: center;
            background-color: #fff9f9;
          }
          .dth-episode-catalog-icon {
            width: var(--dl-size-size-small);
            height: var(--dl-size-size-small);
            margin-right: var(--dl-space-space-unit);
          }
          .dth-episode-catalog-icon2 {
            width: var(--dl-size-size-small);
            height: var(--dl-size-size-small);
          }
          .dth-episode-catalog-root-class-name {
            align-self: center;
          }

          @media (max-width: 320px) {
            .dth-episode-catalog-container3 {
              height: auto;
            }
            .dth-episode-catalog-container5 {
              width: 15%;
            }
            .dth-episode-catalog-icon2 {
              display: none;
            }
            .dth-episode-catalog-root-class-name {
              width: var(--dl-size-size-large);
              align-self: center;
            }
          }
        `}
      </style>
    </>
  )
}

DTHEpisodeCatalog.defaultProps = {
  title: 'Episode Title',
  rootClassName: '',
  duration: '00:00',
}

DTHEpisodeCatalog.propTypes = {
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  url: PropTypes.string,
  duration: PropTypes.string,
}

export default DTHEpisodeCatalog
