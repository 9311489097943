import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import DTHEPICARD from '../components/dthepicard';
import EraSelector from '../components/EraCard';
import NewsCard from '../components/NewsCard';
import './home.css';
import { useAudioPlayer } from '../components/Context';

const Home = (props) => {
  const [episodes, setEpisodes] = useState([]);
  const [news, setNews] = useState([]);
  const [Sponsor, setSponsor] = useState({});
  const [sponsorStyle, setSponsorStyle] = useState({});
  const { audioTitle } = useAudioPlayer()

  const apikey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI';

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const response = await axios.get(
          'https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Episodes?order=pubdate.desc&limit=10',
          {
            headers: {
              apiKey:
                apikey
            },
          }
        );
        setEpisodes(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await axios.get(
          'https://hamradioshowpodcast.com/wp-json/wp/v2/posts?categories=24,26,27,28,30'
        );
        setNews(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSponsor = async () => {
      try {
        const response = await axios.get(
          'https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Sponsors',
          {
            headers: {
              apiKey: apikey,
            },
          }
        );
        const sponsors = response.data;
        if (sponsors.length > 0) {
          const randomIndex = Math.floor(Math.random() * sponsors.length);
          const selectedSponsor = sponsors[randomIndex];
          console.log('Randomly selected Sponsor ID:', selectedSponsor.id); // Log the selected sponsor ID
          setSponsor(selectedSponsor);
          setSponsorStyle(selectedSponsor.SponsorStyle);
        } else {
          console.error('No sponsors data available.');
        }
      } catch (error) {
        console.error(error);
      }
    };


    

    fetchEpisodes();
    fetchNews();
    fetchSponsor();
  }, []);

  return (
    
    <>
<div 
  className="home-container"  >
 
  
  <Helmet>
    <title>{audioTitle ? audioTitle : "DemandTheHam - The Ham Radio Show"}</title>
    <meta property="og:title" content="Nick Test" />
    <meta property="og:description" content="#DemandTheHAM!" />
    <meta
      property="og:image"
      content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
    />
  </Helmet>
  <div id="WS" className="home-container1" ></div>
  <div id="SponsorTitle" className="sponsor-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: sponsorStyle.BGColor}}>
    <h1 className='Sponsor-text' style={{ textAlign: 'center', color: 'grey' }}>Presented by:</h1>
    
    <img src={Sponsor.SponsorLogo} alt="Sponsor" onClick={() => window.open(Sponsor.SPNSRLink, '_blank')} />
  </div>
  <span 
    className="home-text" 
    style={{backgroundColor: sponsorStyle.BannerBG}}>
    <span>Latest Episodes</span>
    <br></br>
  </span>
  <div
    id="DTH_Episodes_Gird"
    className="home-container2"
    style={{ textAlign: 'center', backgroundColor: 'white !important', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {episodes
      .sort((a, b) => new Date(b.pubdate) - new Date(a.pubdate))
      .slice(0, 10)
      .map((episode) => (
        <div key={episode.objectID} className="Episode-item" style={{ margin: '10px', display: 'flex', justifyContent: 'center',  backgroundColor: 'white !important'}}>
          <DTHEPICARD
            CardTitle={episode.title}
            EpisodeDescript={episode.description}
            episode={episode}
            url={episode['EpisodeUrl']}
            BgImage={episode.PodcastImage ? episode.PodcastImage : 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg'}
          />
        </div>
      ))}
  </div>
  <div id="Era_Grid" className="home-container3">
    <div className="eracontainer" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
      <EraSelector Erastation="GOOM Radio" />
      <EraSelector Erastation="MLR" />
      <EraSelector Erastation="PPR" />
      <EraSelector Erastation="UFRN" />
    </div>
  </div>
  <span 
    className="home-text3" 
    style={{backgroundColor: sponsorStyle.BannerBG}}>
    <span>Direct From The Bunker</span>
    <br></br>
  </span>
  <div className="home-container4"style={{backgroundColor:'#fffff'}}>
    {news.map((item) => (
      <div style={{ margin: '8px' }}>
        <NewsCard
          key={item.id}
          Title={item.title.rendered}
          Subtext={item.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")}
          PostID= {item.id}
          BGImage= {item.better_featured_image ? item.better_featured_image.source_url : 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg'}
        />
      </div>
    ))}
  </div>
</div>
</>
);
};
export default Home;