import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAudioPlayer } from './Context';
import PropTypes from 'prop-types';
import { useAuth } from './Auth';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';
import MoreDrawer from './more-drawer';
import ReactGA from 'react-ga4';

import './dth-global-player-web.css';

const DTHGlobalPlayerWeb = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isPlaying,
    setIsPlaying,
    audioSrc,
    setAudioSrc,
    audioTitle,
    setAudioTitle,
    audioImg,
    setAudioImg,
    isLive,
    setIsLive,
    duration,
    setDuration,
    TPBGImage,
    SetTPBGImage,
    BGImage,
    SetBGImage,
    audioSubtext,
    setAudioSubtext,
    isFav,
    setIsFav,
    isCasting,
    SetisCasting
  } = useAudioPlayer();

  const audioRef = useRef(null);
  const { user } = useAuth();
  const [currentTime, setCurrentTime] = useState(0);
  const history = useHistory();

  // Effect to handle audio source changes for casting
  const cjs = new Castjs();


  useEffect(() => {
    if (audioSrc) {
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the audio immediately
        setTimeout(() => {
          audioRef.current.src = audioSrc; // Set new audio source
          if (isPlaying) {
            audioRef.current.play(); // Play the new audio if it was previously playing
          }
        }, 500); // Wait for 1 second before playing the new audio
      }
    }
  }, [audioSrc, isPlaying]);

  const castPlayPauseHandler = () => {
    if (isCasting) {
      if (isPlaying) {
        cjs.pause();
      } else {
        cjs.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const casthandleSeek = (seekTime) => {
    if (cjs.connected) {
      cjs.seek(seekTime);
    }
  };

  const startCasting = (audioSrc, audioTitle, BGImage) => {
    if (!cjs.connected) {
      cjs.cast(audioSrc, {
        title: audioTitle,
        poster: BGImage ? BGImage : './gahamradio-800w.png',
        description: 'DemandTheHAM',
      });

      cjs.on('connected', () => {
        SetisCasting(true);
      });

      cjs.on('update', (state) => {
        // Add code to update your UI based on state
      });
    }
  };

  const castchangeAudioSourceWhileCasting = (audioSrc) => {
    if (cjs.connected) {
      cjs.disconnect();
      cjs.on('disconnected', () => {
        startCasting(audioSrc);
      });
    } else {
      startCasting(audioSrc);
    }
  };

  const casthandleDisconnect = (SetisCasting) => {
    if (cjs.connected) {
      cjs.disconnect();
      SetisCasting(false);
    }
  };

  useEffect(() => {
    if (isCasting && audioRef.current) {
      audioRef.current.muted = true;
    } 
    if (!isCasting && audioRef.current) {
      audioRef.current.muted = false;
    }
  }, [isCasting]);
  // Sync audio properties to database
  useEffect(() => {
    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle || '',
        Duration: duration || 0,
        AudioImg: audioImg || '',
        AudioSrc: audioSrc || '',
        currentTime: currentTime || 0,
      };
      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  }, [user, audioTitle, duration, audioImg, audioSrc, currentTime]);

  // Handle play/pause
  const handlePlayPause = () => {
    setIsPlaying(prevIsPlaying => !prevIsPlaying);
    if (isPlaying) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    } else {
      if (audioRef.current) {
        audioRef.current.play();
      }
    }
    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      if (!isPlaying && audioRef.current) {
        const audioSyncData = {
          AudioTitle: audioTitle || '',
          Duration: duration || 0,
          AudioImg: audioImg || '',
          AudioSrc: audioSrc || '',
          currentTime: currentTime || 0,
        };
        const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
        set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
          console.error("Error updating ActiveAudioSync:", error);
        });
      }
    }
  };

  const handleSeek = (e) => {
    const seekTime = parseFloat(e.target.value);
    setCurrentTime(seekTime);
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime;
    }
    if (user && !isPlaying) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle,
        Duration: duration,
        AudioImg: audioImg,
        AudioSrc: audioSrc,
        currentTime: seekTime,
      };
      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  };

  const handleLoadedData = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  // Time update listener
  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audioRef.current.currentTime);
    };
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('loadeddata', handleLoadedData);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('loadeddata', handleLoadedData);
      }
    };
  }, []);

  const LogoOnClick = () => {
    history.push('/');
  };

  const handleContainer5Click = () => {
    setIsModalOpen(true);
  };

  const handleModalOutsideClick = (event) => {
    if (event.target.classList.contains('modal-container')) {
      setIsModalOpen(false);
    }
  };

  const LiveRadioHandler = () => {
    setIsLive(true);
    setAudioSrc("https://Stream.thehamradio.show");
    setAudioTitle("The Ham Radio Show");
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  // Fetch ICY metadata if live
  useEffect(() => {
    const fetchIcyMetadata = async () => {
      try {
        const response = await fetch('https://streaming.live365.com/listen.pls?station=nativevoice', {
          method: 'GET',
          headers: { 'Icy-MetaData': '1' }
        });
        const metadata = response.headers.get('icy-metaint');
        if (metadata) {
          const metadataInterval = setInterval(async () => {
            const reader = response.body.getReader();
            const result = await reader.read();
            if (result.done) {
              clearInterval(metadataInterval);
            }
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(result.value);
            const match = /StreamTitle='([^;]+)';/.exec(text);
            if (match) {
              const [artist, title] = match[1].split(' - ');
              setAudioTitle(title);
              setAudioSubtext(artist);
            }
          }, 60000); // Fetch metadata every 60 seconds
        }
      } catch (error) {
        console.error('Error fetching ICY metadata:', error);
      }
    };
    if (isLive) {
      fetchIcyMetadata();
      const intervalId = setInterval(fetchIcyMetadata, 300000); // Fetch new data every 5 minutes
      return () => clearInterval(intervalId);
    }
  }, [isLive]);

  // Remove any undefined properties from the object
  const removeUndefinedProperties = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== undefined) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };

  return (
    <div className="dth-global-player-web-container">
      <audio ref={audioRef} src={audioSrc} hidden></audio>
      <div className="dth-global-player-web-container1">
        <div style={{ backgroundColor: "white", padding: "10px", borderRadius: "5px" }}>
          {isPlaying ? (
            <svg
              viewBox="0 0 808.5942857142857 1024"
              className="dth-global-player-web-icon"
              style={{ width: "32px", height: "48px", fill: "black" }} // Made the SVG white
              onClick={handlePlayPause}
            >
              <path d="M270 96L270 928 370 928 370 96zM445 96L445 928 545 928 545 96z"></path>
            </svg>
          ) : (
            <img
              src="https://bunnydojo.com/2020/2020-play.png"
              className="dth-global-player-web-icon"
              style={{ width: "48px", height: "48px" }} // Resized to 48x48
              onClick={handlePlayPause}
              alt="Play"
            />
          )}
        </div>
        )}
      </div>
      <div className="dth-global-player-web-container2" onClick={LogoOnClick}>
        <img
          alt={props.imageAlt}
          src="/ham%20logo-200h.png"
          className="dth-global-player-web-image"
        />
      </div>
      <div
        id="DTH_BGCotainer"
        className="dth-global-player-web-container3"
        style={{
          backgroundImage: `url(${BGImage ? BGImage : props.PlayerBG})`,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backgroundPosition: 'top',
            backgroundSize: 'Cover',
            backgroundRepeat: 'no-repeat'
        }}
      >
        <div
          className="dth-global-player-web-container4"
          style={{
            backgroundImage: `url(${TPBGImage ? TPBGImage : null})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <span className="dth-global-player-web-text">
            {audioSubtext ? audioSubtext : "Why? Because We Can!"}
          </span>
          <span className="dth-global-player-web-text1">{audioTitle ? audioTitle : "The Ham Radio Show"}</span>

          <div className="dth-global-player-web-container5" onClick={handleContainer5Click}>
            <svg viewBox="0 0 1024 1024" className="dth-global-player-web-icon2">
              <path d="M597.333 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM896 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331zM298.667 512c0-23.552-9.6-44.928-25.003-60.331s-36.779-25.003-60.331-25.003-44.928 9.6-60.331 25.003-25.003 36.779-25.003 60.331 9.6 44.928 25.003 60.331 36.779 25.003 60.331 25.003 44.928-9.6 60.331-25.003 25.003-36.779 25.003-60.331z"></path>
            </svg>
          </div>
          <input
            id='PlayerSlider'
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            className="dth-global-player-web-slider"
            style={{
              position: 'absolute',
              bottom: '10px',
              width: '90%',
              left: '5%',
              color: 'grey',
              zIndex: '800'
            }}
            onChange={isCasting ? casthandleSeek : handleSeek}
          />
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-container" onClick={handleModalOutsideClick}>
          <div className="modal-content">
            <MoreDrawer LiveRadioHandler={LiveRadioHandler} />
          </div>
        </div>
      )}
    </div>
  );
};

DTHGlobalPlayerWeb.defaultProps = {
  imageAlt: 'image',
  imageSrc: '/rosiebot222x166-200h.png',
  imageAlt2: 'image',
  dthtitle: 'The Ham Radio Show Presents Cold Cuts',
  dthsubtitle: 'Best Of GOOM Radio Era',
  imageSrc1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  imageAlt1: 'image',
  PlayerBG: '/6513d74e9b7ca76d70274ee327f06d3b.jpg',
  PlayTPBG: ''
};

DTHGlobalPlayerWeb.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt2: PropTypes.string,
  dthtitle: PropTypes.string,
  dthsubtitle: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  PlayerBG: PropTypes.string,
  PlayTPBG: PropTypes.string,
};

export default DTHGlobalPlayerWeb;